import React from 'react';
import { graphql, Link } from 'gatsby';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => (
  <>
    <SEO title={frontmatter.title} description={frontmatter.description} />
    <Layout
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      button={frontmatter.button}
    >
      <section className="page-section">
        <Container className="mb-5" style={{ marginTop: 64 }}>
          <Row>
            <Col xs={12} lg>
              <img src="/img/vision@2x.png" />
            </Col>
            <Col className="ml-lg-5" xs={12} lg>
              <h2 style={{ fontSize: 62, fontWeight: 'bold' }}>Vision</h2>
              <p style={{ fontSize: 20, fontWeight: 'bold' }}>
                The systems that capture data are rarely the same ones where
                data analysis or processing take place.
              </p>
              <p style={{ color: '#000000', fontWeight: 300 }}>
                At the moment, data is "held captive" - it's hard to move around
                as most clickstream sources are monolithic and, even though they
                expose data through APIs, they are hard to use. What few
                resources are there for moving data are either very expensive or
                very unfriendly (often times both), thus not allowing the data
                freedom we want, which, in turn, stifles innovation. We want to
                challenge the status quo! By leveraging our expertise in this
                area and providing YOU access to YOUR data in a friendly manner
                without the high fees, we allow you to focus on the important
                tasks.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page-section bg-secondary text-dark">
        <Container>
          <h2
            className="text-center py-5 text-primary"
            style={{ fontSize: 62, fontWeight: 'bold' }}
          >
            What makes our product different
          </h2>
          <Row>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/secure@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Secure
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Focused on Data Security by using cutting-edge technology that
                  allows data to be imported straight to users’ computers.
                </p>
              </Row>
            </Col>
            <Col className="p-5 step2" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/easy-to-use@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Easy to use
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Friendly UI for a great user experience, point and click
                  installation, brings your Adobe Analytics or Jira data in Tableau without ever leaving
                  the app.
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/Accessible@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Accessible
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Affordable or even free technology as our main goal is
                  improving lives and add value. By being creative with our
                  solution, rather than throwing money at it, not only are we
                  helping to preserve energy, we’re also eliminating expenses
                  related to servers and APIs costs that would be passed on to
                  our users.
                </p>
              </Row>
            </Col>
            <Col xs={12} className="text-center pb-5">
              <Button variant="outline-primary" as={Link} to="/products/">
                Tell me more
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  </>
);

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        button
        description
      }
    }
  }
`;
